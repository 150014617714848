.breadcrumbs-container {
  background-color: #e9ecef;

  #wo-breadcrumbs{
    padding-left: 0;
    padding-right: 0;
    margin: 0;
  }

  .separator{
    margin: 0 5px;
  }
}