@import "variables";
@import "file_manager";
@import "mixins";
@import "colors";
@import "breadcrumbs";
@import "pagination";

.page {
  padding-bottom: 34px;

  & > .content {
    padding-bottom: 30px;
  }
}

.login-page {
  .form-inner {
    width: 100%;
  }

  .form-outer {
    max-width: 400px;
  }
}

.side-navbar {
  .sidenav-header {
    i {
      margin-right: 0;
    }
  }

  i.f-size-2em {
    font-size: 2em;
  }
}

.form-group .d-flex  {
  flex-wrap: wrap;
  padding: 5px 0;

  .file-s3-remove{
    padding: 4px 15px;
  }
}

#user_attachments {
  .form-control-file,
  .file-s3-url {
    margin-bottom: 10px;

    @include breakpoint($sm) {
      margin-bottom: 0;
    }
  }

  .form-control-file,
  .file-s3-remove {
    @include breakpoint($sm) {
      display: inline-block;
      margin-left: 10px;
      width: auto;
    }
  }
}

.attachments {
  div {
    margin: 0;
  }
}

.side-navbar {
  li {
    a {
      &:hover {
        background: #1c6331;
      }

      &[aria-expanded="true"],
      &:focus {
        background: transparent;
      }
    }
  }
}

.projects-dropdown{
  max-height: 400px;
  overflow-y: scroll;
}

.form-check-label {
  width: calc(98% - 1px);
}

.logo {
  max-width: 300px;
  max-height: 300px;

  &.img-thumbnail {
    max-width: 100px;
    max-height: 100px;
  }
}

.details .logo{
  max-width: 150px;
  max-height: 150px;
}

.form-check {
  padding-left: 0;
  margin-bottom: 8px;
}

#security-permissions {
  .form-check-input {
    position: static;
    margin: 0;
  }
}

#file-manager-permissions {
  .form-check-input {
    position: static;
    margin: 0;
  }
}

//.required:after {
//  content: "*";
//  color: red;
//  margin-left: 5px;
//}

.form-check-label.required:after {
  margin-left: inherit;
}

input[type='date'].form-control{
  max-width: 260px;
}

//ACCORDION
#request-accordion {
  .card-header {
    color: white;

    a {
      color: white;
    }
  }
  .card-header a:after {
    font-family: 'FontAwesome', sans-serif;
    content: "\f077";
  }
  .card-header a.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\f078";
  }


  .icon {
    font-size: 40px;
    margin-right: 30px;
    height: 40px;
    line-height: 48px;
  }
}

.hidden {
  display: none!important;
}

input[type="checkbox"][readonly] {
  pointer-events: none;
}

input[type="checkbox"][readonly] + label,
input[type="checkbox"][readonly] + label::after,
input[type="checkbox"][readonly] + label::before{
  cursor: not-allowed!important;
  filter: grayscale(1);
}

input[type="checkbox"][readonly] + label::before{
  background: #33b35a!important;
}


//TABLES
table {
  th a:hover,
  th a:active{
    text-decoration: none;
  }

  th a:after {
    font-family: 'FontAwesome', sans-serif;
    content: "\f0dc";
    font-size: 15px;
    color: lightgray;
    margin-left: 5px;
  }

  th.sorted{
    a.desc:after{
      font-family: 'FontAwesome', sans-serif;
      content: "\f0dd";
      font-size: 15px;
      color: grey;
    }
    a.asc:after{
      font-family: 'FontAwesome', sans-serif;
      content: "\f0de";
      font-size: 15px;
      color: grey;
    }
  }
}

table#ct_style_diary_diaryRows {
  th,
  td {
    padding: 0.75rem 4px;

    & > span {
      &:not(.remove-contract-item) {
        padding: 0.375rem 0;
        height: calc(2.25rem + 2px);
        font-size: 1rem;
        line-height: 1.5;
      }
    }
  }

  td {
    &.remarks {
      width: 20%;
    }
  }

  tr#contract-items {
    height: 202px;

    th {
      position: relative;
      
      &.full,
      &.empty {
        width: 48px;
      }

      &.full {
        padding-left: 15px;

        div,
        select {
          position: absolute;
          width: 182px;
          left: -67px;
          top: 72px;
          transform: rotate(-90deg);
          height: 38px;
        }

        select {
          option:disabled {
            color: #ccc;
          }
        }

        span {
          position: absolute;
          left: 18px;
          bottom: 0;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  th {
    a:after {
      display: none;
    }
  }
}

select.form-control.diary-worker {
  option:disabled {
    color: #ccc;
  }
}

input.form-control.hours-input {
  padding: 0.375rem 0 0.375rem 4px;
  width: 40px;
}

.filter-block{
  .btn-default{
    margin-left: 0;
    width: 100%;

    @include breakpoint($sm) {
      margin-left: 15px;
      width: auto;
    }
  }
}


//FILTER FORM
.filter-form{
  width: 100%;
  margin-top: 15px;

  >* {
    width: 100%;
    margin-bottom: 10px;
  }

  @include breakpoint($sm) {
    width: auto;

  >*{
    width: auto;
    margin-bottom: 0;
  }
  }

  @include breakpoint($ls) {
    margin-top: 0;
  }

  #field-list, #field-input{
    width: 100%;

    @include breakpoint($sm) {
      width: 200px;
    }
  }

  .margin-left-3{
    margin-left: 3px;
  }
}

.custom-file {
  position: static;
  display: inline-block;
  width: auto;
  height: auto;
}

.custom-file-label {
  display: none;
}

ul li .badge {
  display: inline-block;
  min-width: 20px;
  padding: 3px;
  font-size: 11px;
  font-weight: bold;
  color: #ffffff;
  line-height: 13px;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #777777;
  border-radius: 50%;
  position: relative;
  right: 10px;
  bottom: 8px;

}

.label_bold {
  font-weight: bold;
}
