.fm-container {
  width: 100%;
  .fm-preview-view-panel iframe {
    width: 100%;
  }
}
.spinner {
  height: 50vh;
  i {
    margin-top: 30vh;
  }
}